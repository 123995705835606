<template>
    <div class="container" id="content">
      <div class="title">
        <div class="title-top">
          <div class="el-title-item">
            <el-form ref="form" label-width="80px">
              <el-form-item label="">
                <el-button type="success" size="small" @click="addOption">新增</el-button>
              </el-form-item>
              <el-form-item label="" style="margin-left: 2vw">
                <el-input v-model="searchName" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button>
        </div>
      </div>
      <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 1vh;">
        <el-table-column show-overflow-tooltip label="序号" align="center" :index="indexMethod" width="100"
        type="index"></el-table-column>
        <el-table-column prop="productName" label="产品名称" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="harvestNumber" label="采收批次号" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="warehousingCount" label="入库数量" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="warehousingUnit" label="入库规格" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="inventoryLocation" label="库存位置" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="recorder" label="负责人" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="recorderTel" label="负责人电话" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="content" label="备注" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.content">{{ scope.row.content }}</span>
            <span v-else>暂无备注</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-edit"
              @click="edits(scope.row)"
            ></el-button>
            <el-button
              type="primary"
              size="small"
              icon="el-icon-delete"
              @click="deletes(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block pagingBox">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
      <!--    编辑-->
      <el-dialog title="" :visible.sync="messageBox" width="31vw" class="addAlameBox">
        <div slot="title" class="header-title" style="position: relative">
          <span class="title-name"></span>
          <span class="title-age">{{ formTitle }}</span>
          <span style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0px; font-size: 2.22vh;"></span>
        </div>
        <div class="addAlaForm">
          <el-form ref="addForm" :rules="rules" :model="addForm" label-width="11vw">
            <el-form-item label="产品名称" prop="productName">
              <!-- <el-date-picker v-model="addForm.nyr" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker> -->
              <el-input :disabled="editMse" v-model="addForm.productName" placeholder="请输入产品名称"></el-input>
            </el-form-item>
            <el-form-item label="采收批次" prop="harvestNumber">
                <el-select :disabled="editMse" v-model="addForm.harvestNumber" placeholder="请选择采收批次">
                    <el-option v-for="self in harvestData" :key="self.id" :label="self.harvestNumber" :value="self.harvestNumber"></el-option>
                </el-select>
              <!-- <el-input v-model="addForm.harvestNumber" placeholder="请输入作物类型"></el-input> -->
            </el-form-item>
            <el-form-item label="入库数量" prop="warehousingCount">
              <!-- <el-date-picker v-model="addForm.nyr" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker> -->
              <el-input :disabled="editMse" type="number" v-model="addForm.warehousingCount" placeholder="请输入入库数量"></el-input>
            </el-form-item>
            <el-form-item label="入库规格" prop="warehousingUnit">
              <el-input :disabled="editMse" v-model="addForm.warehousingUnit" placeholder="请输入入库规格"></el-input>
            </el-form-item>
            <el-form-item label="库存位置" prop="inventoryLocation">
              <!-- <el-date-picker v-model="addForm.nyr" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker> -->
              <el-input :disabled="editMse" v-model="addForm.inventoryLocation" placeholder="请输入库存位置"></el-input>
            </el-form-item>
            <el-form-item label="负责人" prop="recorder">
              <el-input v-model="addForm.recorder" placeholder="请输入负责人"></el-input>
            </el-form-item>
            <el-form-item label="负责人电话" prop="recorderTel">
              <el-input v-model="addForm.recorderTel" placeholder="请输入负责人电话"></el-input>
            </el-form-item>
            <el-form-item label="时间" prop="time">
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="addForm.time" type="datetime" placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="备注" prop="content">
              <el-input v-model="addForm.content" placeholder="请输入负责人"></el-input>
            </el-form-item>
            <el-form-item class="isUpload" label="产品图片" prop="productImg" v-show="editMse == false">
                <el-upload
                action="/imgUpdata"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload2"
                list-type="picture-card"
                :file-list="cImgfileList"
                :headers="{
                  token
                }"
                :limit="1"
                >
                <!-- <img
                    v-if="addForm.imgUrl"
                    :src="addForm.imgUrl"
                    class="businessImg avatar"
                /> -->
                <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
            <!-- productImg -->
          </el-form>
          <div slot="footer" class="dialog-footer" v-show="showFlag">
            <el-button type="info" @click="messageBox = false">取消</el-button>
            <el-button type="primary" @click="saveForm('addForm')">确定</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
  export default {
    // name: 'sensorManagement',
    data() {
      return {
        token: '',
        tableData: [],
        page: 0,
        size: 10,
        total: 0,
        loading: false,
        ids: 0,
        searchName: '',
        addForm: {},
        rules: {
            productName: [
                {required: true, message: "产品名称不可为空", trigger: ["blur", "change"]},
            ],
            harvestNumber: [
                {required: true, message: "采收批次不可为空", trigger: ["blur", "change"]},
            ],
            warehousingCount: [
                {required: true, message: "入库数量不可为空", trigger: ["blur", "change"]},
            ],
            warehousingUnit: [
                {required: true, message: "入库规格不可为空", trigger: ["blur", "change"]},
            ],
            inventoryLocation: [
                {required: true, message: "库存位置不可为空", trigger: ["blur", "change"]},
            ],
            recorder: [
                {required: true, message: "负责人不可为空", trigger: ["blur", "change"]},
            ],
            recorderTel: [
                {required: true, message: "负责人电话不可为空", trigger: ["blur", "change"]},
            ],
          time: [
            {required: true, message: "时间不可为空", trigger: ["blur", "change"]},
          ],

        },
        formTitle: "",
        messageBox: false,
        showFlag: false,
        // value: true,
        pageSize: 10,
        pageNow: 1,
        cImgfileList: [],
        harvestData: [],
        editMse: false,
        imgUrlList: '',
      };
    },
    mounted() {
      this.token = JSON.parse(window.localStorage.getItem("token"));
      this.getList();
      this.getHarvestData();
    },
    methods: {
        // 序号
        indexMethod(index) {
        return index + this.pageSize * (this.pageNow - 1) + 1;
        },
      //请求列表数据
      getList() {
        this.$get("/productWarehousing/getProductWarehousings", {
          page: this.page,
          size: this.size,
          productName: this.searchName,
        }).then((res) => {
          if (res.data.state == "success") {
            this.tableData = res.data.datas;
            this.total = res.data.count;
            //console.log("获取列表", this.tableData)
          }
        });
      },
    //   获取采收批次列表
      getHarvestData(){
        this.$get("/harvestManagement/getHarvestManagements", {
          page: 1,
          size: 10000,
        }).then((res) => {
          if (res.data.state == "success") {
            this.harvestData = res.data.datas;
            //console.log("获取列表", this.tableData)
          }
        });
      },
      // 编辑
      edits(row) {
        //console.log(row);
        this.showFlag = true;
        this.editMse = true;
        this.ids = row.id;
        this.formTitle = "编辑";
        this.messageBox = true;
        this.addForm = JSON.parse(JSON.stringify(row));
      },
      // 删除
      deletes(id) {
        // 弹出提示是否删除
        this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$delete(`/productWarehousing/delProductWarehousing/${id}`).then((res) => {
              if (res.data.state == "success") {
                this.$message.success("删除成功");
                this.getList();
              } else {
                this.$message.error("删除失败");
              }
            });
          })
          .catch((e) => e);
      },
      // 确认提交
      saveForm(formName) {
        // let id = this.ids;
        this.addForm.id = this.ids;
        this.addForm.createUser = window.localStorage.getItem('user')
        this.addForm.productImg = this.imgUrlList;
        //console.log(this.addForm);
        let url = '/productWarehousing/addOrUpdateProductWarehousing';
        if(this.formTitle == '新增'){
          delete this.addForm.id;
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$postJSON(url, this.addForm
            ).then((res) => {
              if (res.data.state == 'success') {
                if(this.formTitle == '新增'){
                  this.$message.success('添加成功')
                }else{
                  this.$message.success('修改成功')
                }    
                this.messageBox = false
                this.getList();
              } else {
                this.$message.error(res.data.msg)
                this.messageBox = false
              }
            })
          } else {
            // //console.log('error submit!!');
            return false;
          }
        });
      },
      // 新增
      addOption() {
        this.formTitle = "新增";
        this.messageBox = true;
        this.editMse = false;
        this.showFlag = true;
        this.$nextTick(() => {
          this.addForm = {};
          this.imgUrlList = '';
          this.$refs.addForm.resetFields();
        });
      },
      // 搜索
      search() {
        this.page = 1
        this.getList();
      },
      handleSizeChange(val) {
        this.size = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.page = val;
        this.getList();
      },

      beforeAvatarUpload2(val) {
      const fileLimitSize = 2;// 几兆限制
      const isJPG = val.type === "image/jpeg";
      const isJPGs = val.type === "image/png";
      const fileLimit = val.size / 1024 / 1024 < fileLimitSize;
      if (!isJPG && !isJPGs) {
        this.$message.info("上传图片格式只能为 JPG 或 PNG 格式！");
        this.fileList2 = [];
        return false
      }
      if (!fileLimit) {
        this.$message.info(`上传图片大小不能超过${fileLimitSize}MB!`);
        this.fileList2 = [];
        return false
      }

      return true;
    },
    handleAvatarSuccess2(res, file) {
        // this.cImgfileList.push(file);
        // this.addForm.productImg = "http://cloudback.half-half.cn/" + res.data;
        // //console.log(this.addForm.productImg)
        let imgUrl = res.data;
        this.$getBlob("/fileops/show", {
        fileName: res.data,
      }).then((res) => {
        //console.log(res,"图片");
        let fileNames = res.config.params.fileName;
        let fileData = fileNames.split(".")[1];
        //console.log(fileData);
          let blob = new Blob([res.data],{type: 'image/'+ fileData})
        let url = window.URL.createObjectURL(blob);
        this.addForm.productImg = url;
        this.imgUrlList = imgUrl;
      })
    },
    },
  };
  </script>
  
  <style scoped lang="less">
  @import "../../../style/backTable.less";
  </style>
  <style scoped>
  #content /deep/ .el-table th {
    background-color: rgba(240, 242, 245, 1) !important;
  }
  
  #content /deep/ .el-table {
    max-height: 78vh;
    overflow-y: scroll;
  }
  
  #content /deep/ .el-table::-webkit-scrollbar {
    display: none;
  }
  
  .addAlaForm /deep/ .el-select {
    width: 100% !important;
  }
  
  .dialog-footer {
    height: 5vh;
    text-align: right;
  }
  
  .dialog-footer /deep/ .el-button, .el-button--info {
    margin-left: 10px !important;
  
  }
  
  .dialog-footer /deep/ .el-button--info {
    background-color: transparent !important;
    color: #000;
  }
  
  .title /deep/ .el-input__inner {
    height: 33px !important;
  }
  
  .drawerBox /deep/ .msg {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1vh 1vw;
  }
  
  </style>
  
  
  